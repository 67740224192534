import React from 'react'
import ReactDOM from 'react-dom/client'
import {AuthLoader} from '@zetadisplay/engage-components/modules/auth';

import './index.css';
import {AppLazyLoad} from './Layout/AppLazyLoad';
//import App from './Layout/App'


ReactDOM.createRoot(document.getElementById('zd_se')!).render(
  <React.StrictMode>
      <React.Suspense fallback={<AuthLoader/>}>
          <AppLazyLoad/>
      </React.Suspense>
  </React.StrictMode>,
)
